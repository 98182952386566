<template>
    <div>
        <b-card>
            <div class="d-flex justify-content-start align-items-center mb-1">
                <!-- avatar -->
                <b-avatar :src="$store.state.auth.user.current_brand_image" size="50" class="mr-1" />
                <!--/ avatar -->
                <div class="profile-user-info">
                    <h6 class="mb-0">{{ $store.state.auth.user.current_brand_name }}</h6>
                    <small class="text-muted">{{ $store.state.auth.user.current_brand_signature }}</small>
                </div>
                <div class="ml-auto">
                    <b-dropdown no-caret variant="link">
                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                        </template>
                        <b-dropdown-item>
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">delete</span>
                        </b-dropdown-item>
                        <b-dropdown-item>
                            <feather-icon icon="InfoIcon" />
                            <span class="align-middle ml-50">Info</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
            <b-card-text style="white-space: pre;">{{ post.caption }}</b-card-text>

            <b-row class="justify-content-md-center">
                <b-col cols="12" v-if="post.type == 'normal' && post.medias.length != 0">
                    <b-carousel id="carousel-example-generic" controls indicators style="width: 100%; height: 100%;"
                        v-if="post.medias.length > 1">
                        <b-carousel-slide v-for="media in post.medias" :key="media.id">
                            <template #img>
                                <img class="d-block img-fluid w-100" :src="media.media" alt="image slot"
                                    style="width: 100%; max-height: 300px;">
                            </template>
                        </b-carousel-slide>
                    </b-carousel>
                    <img v-else class="d-block img-fluid w-100" :src="post.medias[0].media" alt="image slot"
                        style="width: 100%; max-height: 300px;">
                </b-col>
                <b-col cols="12" v-else>
                    <!-- post video -->
                    <div v-for="media in post.medias" :key="media.id">
                        <b-embed controls type="video" aspect="4by3">
                            <source :src="media.media">
                        </b-embed>
                    </div>
                    <!--/ post video -->
                </b-col>
            </b-row>
            <hr>
            <b-row class="mt-50">
                <b-col sm="12" class="d-flex justify-content-between">
                    <b-link class="text-body text-nowrap">
                        <feather-icon icon="ThumbsUpIcon" size="18" class="profile-icon mr-50" />
                        <span class="text-muted">Like</span>
                    </b-link>
                    <b-link class="text-body text-nowrap">
                        <feather-icon icon="MessageCircleIcon" size="18" class="profile-icon mr-50" />
                        <span class="text-muted">Comment</span>
                    </b-link>
                    <b-link class="text-body text-nowrap">
                        <feather-icon icon="RepeatIcon" size="18" class="profile-icon mr-50" />
                        <span class="text-muted">Share</span>
                    </b-link>
                    <b-link class="text-body text-nowrap">
                        <feather-icon icon="SendIcon" size="18" class="profile-icon mr-50" />
                        <span class="text-muted">Send</span>
                    </b-link>
                </b-col>
                <b-col cols="12"><hr></b-col>
            </b-row>
        </b-card>
    </div>
</template>


<script>
import { 
    BButton, BCardText, BAvatar, BRow, BCol, BMedia, BFormRadio, BLink, BCard,
    BDropdown, BDropdownItem, BCarousel, BCarouselSlide, BImg, BEmbed
} from 'bootstrap-vue'
export default {
    components: {
        BButton, BCardText, BAvatar, BRow, BCol, BMedia, BFormRadio, BLink, BCard,
        BDropdown, BDropdownItem, BCarousel, BCarouselSlide, BImg, BEmbed
    },
    props:{
        post: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
    }
}
</script>