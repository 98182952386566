<template>
    <div class="d-flex justify-content-center">
        <div class="d-flex justify-content-center align-items-center" style="border-radius: 1rem;width:300px;height: 450px; background: black;position:relative;">
            <div v-if="post.medias.length > 0" style="max-height: 450px;width:300px;position:absolute;z-index: 0;">
                <b-col cols="12" v-if="post.type == 'normal' && post.medias.length != 0">
                    <b-carousel id="carousel-example-generic" controls indicators style="width: 100%; max-height: 450px"
                        v-if="post.medias.length > 1">
                        <b-carousel-slide v-for="media in post.medias" :key="media.id">
                            <template #img>
                                <img class="d-block img-fluid w-100" :src="media.media" alt="image slot"
                                    style="width: 100%; max-height: 450px">
                            </template>
                        </b-carousel-slide>
                    </b-carousel>
                    <img v-else class="d-block img-fluid w-100" :src="post.medias[0].media" alt="image slot"
                        style="width: 300px; max-height: 450px">
                </b-col>
                <video v-else autoplay muted loop style="width: 300px; max-height: 450px">
                    <source :src="post.medias[0].media" type="video/mp4">
                    Your browser does not support HTML5 video.
                </video>
            </div>
            <div style="left:0 !important;bottom: 10px;width: 300px;max-height: 200px;position:absolute;z-index: 1;">
                <b-row class="pb-50 mt-50">
                    <b-col sm="12" class="d-flex justify-content-between justify-content-sm-end mb-1">
                        <b-link class="text-body text-nowrap">
                            <feather-icon style="color: white;" icon="HeartIcon" class="mr-50 profile-icon" size="18" />
                        </b-link>
                    </b-col>
                    <b-col sm="12" class="d-flex justify-content-between justify-content-sm-end mb-1">
                        <b-link class="text-body text-nowrap">
                            <feather-icon style="color: white;" icon="MessageCircleIcon" class="mr-50 profile-icon" size="18" />
                        </b-link>
                    </b-col>
                    <b-col sm="12" class="d-flex justify-content-between justify-content-sm-end mb-1">
                        <b-link class="text-body text-nowrap">
                            <feather-icon style="color: white;" icon="BookmarkIcon" class="mr-50 profile-icon" size="18" />
                        </b-link>
                    </b-col>
                    <b-col sm="12" class="d-flex justify-content-between justify-content-sm-end mb-1">
                        <b-link class="text-body text-nowrap">
                            <feather-icon style="color: white;" icon="RepeatIcon" class="mr-50 profile-icon" size="18" />
                        </b-link>
                    </b-col>
                    <b-col sm="12" class="d-flex justify-content-between justify-content-sm-start mb-1 ml-1">
                        <div>
                            <h6 style="color: white;">{{ post.title }}</h6>
                            <p style="color: rgb(208, 206, 206); font-size: 7pt;width: 280px;" v-text="post.caption.length >= 110 ? `${post.caption.substr(0, 110)}...`:post.caption.substr(0, 110)"></p>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>


<script>
import { 
    BButton, BCardText, BAvatar, BRow, BCol, BMedia, BFormRadio, BLink, BCard,
    BDropdown, BDropdownItem, BCarousel, BCarouselSlide, BImg, BEmbed
} from 'bootstrap-vue'
export default {
    components: {
        BButton, BCardText, BAvatar, BRow, BCol, BMedia, BFormRadio, BLink, BCard,
        BDropdown, BDropdownItem, BCarousel, BCarouselSlide, BImg, BEmbed
    },
    props:{
        post: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
    }
}
</script>