<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column">
        <calendar-sidebar :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"
          @showAddPost="showAddPost" />
      </div>

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar ref="refCalendar" :options="calendarOptions" class="full-calendar" />
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="showPostModal" no-close-on-backdrop id="best" size="xl">
      <template #modal-header="{ close }">
        <!-- media -->
        <b-media no-body vertical-align="center">
          <b-media-aside class="mr-75">
            <b-avatar size="38" :src="$store.state.auth.user.current_brand_image" />
          </b-media-aside>
          <b-media-body>
            <h6 class="mb-25">{{ $store.state.auth.user.current_brand_name }}</h6>
            <b-card-text>{{ $store.state.auth.user.current_brand_signature }}</b-card-text>
          </b-media-body>
        </b-media>
        <!--/ media -->
      </template>

      <template #default="{ hide }">
        <b-row>
          <b-col xl="8" lg="8" md="8" sm="12" cols="12">
            <validation-observer ref="simpleRules">
              <!-- form -->
              <b-form class="mt-2">
                <b-row>
                  <b-col md="12">
                    <b-alert show variant="danger" v-for="error in post_errors" :key="error" class="mb-75">
                      <div class="alert-body">
                        <feather-icon icon="InfoIcon" class="mr-50" />
                        {{ error }}
                      </div>
                    </b-alert>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="Channels" label-for="blog-edit-category" class="mb-2">
                      <validation-provider #default="{ errors }" name="Channels" rules="required">
                        <v-select id="blog-edit-category" v-model="post.channel_ids"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="channels"
                          :state="errors.length > 0 ? false : null" label="title" :reduce="item => item.id" multiple>
                          <template #option="{ title, username, type, image_url }">
                            <div class="d-flex justify-content-start align-items-center">
                              <!-- <b-img rounded :src="image_url" blank-color="#ccc" width="40"
                                                                alt="placeholder" class="mr-2" /> -->
                              <b-avatar class="mr-75" size="50" :src="image_url" badge-variant="default">
                                <template #badge>
                                  <b-avatar size="20" :src="getChannelTypeImage(type)" variant="default" />
                                </template>
                              </b-avatar>
                              <div class="user-page-info ml-75">
                                <h6 class="mb-0">{{ title }}</h6>
                                <small class="text-muted">{{ username }}</small>
                              </div>
                            </div>
                          </template>

                          <template #selected-option="{ title, type, image_url }">
                            <b-avatar size="sm" :src="image_url" badge-variant="default">
                              <template #badge>
                                <b-avatar size="10" :src="getChannelTypeImage(type)" variant="default" />
                              </template>
                            </b-avatar>
                            <span class="ml-25">{{ title }} - {{ type }}</span>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Title" label-for="blog-edit-title" class="mb-2">
                      <validation-provider #default="{ errors }" name="Title" rules="required">
                        <b-form-input id="blog-edit-title" v-model="post.title"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Type" label-for="blog-edit-category" class="mb-2">
                      <validation-provider #default="{ errors }" name="Type" rules="required">
                        <v-select id="blog-edit-category" v-model="post.type"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="categoryOptions"
                          :state="errors.length > 0 ? false : null" label="name" :reduce="item => item.name"
                          @input="post.medias = []">

                          <template #option="{ name, icon }">
                            <feather-icon :icon="icon" size="16" class="align-middle mr-50" />
                            <span> {{ name }}</span>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Caption" label-for="blog-content" class="mb-2">
                      <validation-provider #default="{ errors }" name="Caption" rules="required">
                        <b-form-textarea v-model="post.caption" id="textarea-default"
                          :state="errors.length > 0 ? false : null" placeholder="Caption !" rows="3" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" class="mb-2" v-if="post.type == 'normal'">
                    <div class="border rounded p-2">
                      <h4 class="mb-1">Attachements</h4>
                      <span class="d-inline-block m1 " v-for="image, index in post.medias" :key="index"
                        @mouseenter="media = index" @mouseleave="media = -1">
                        <b-overlay opacity="1" variant="transperant" :show="media == index">
                          <b-img height="75" width="75" :src="image.media" class="d-inline-block mr-1 mb-1 m1 border"
                            rounded />
                          <template #overlay>
                            <div class="text-center">
                              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="danger"
                                class="btn-icon mr-75 mb-75" size="sm" @click="removeMedia(index)">
                                <feather-icon icon="Trash2Icon" />
                              </b-button>
                            </div>
                          </template>
                        </b-overlay>
                      </span>
                      <b-link @click="showMediaModal = true">
                        <b-avatar rounded="sm" variant="white" size="75" class="d-inline-block mr-1 mb-1 popular">
                          <feather-icon icon="PlusIcon" class="text-primary" size="50" />
                        </b-avatar>
                      </b-link>
                    </div>
                  </b-col>
                  <b-col v-else cols="12" class="mb-2">
                    <div class="border rounded p-2">
                      <h4 class="mb-1">Video</h4>
                      <b-row>
                        <b-col v-for="video in post.medias" :key="video.id" cols="4"
                          @mouseenter="showRemoveVideo = true" @mouseleave="showRemoveVideo = false">
                          <b-overlay opacity="1" variant="transperant" :show="showRemoveVideo">
                            <b-embed class="d-inline-block mr-1 mb-1 m1" type="video" aspect="4by3">
                              <source :src="video.media">
                            </b-embed>
                            <template #overlay>
                              <div class="text-center">
                                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="danger"
                                  class="btn-icon mr-75 mb-75" size="sm" @click="post.medias = []">
                                  <feather-icon icon="Trash2Icon" />
                                </b-button>
                              </div>
                            </template>
                          </b-overlay>
                        </b-col>
                      </b-row>
                      <b-link v-if="post.medias.length == 0" @click="showMediaModal = true">
                        <b-avatar rounded="sm" variant="white" size="75" class="d-inline-block mr-1 mb-1 popular">
                          <feather-icon icon="PlusIcon" class="text-primary" size="50" />
                        </b-avatar>
                      </b-link>
                    </div>
                  </b-col>
                  <b-col cols="12" class="mb-2">
                    <div class="border rounded p-2">
                      <h4 class="mb-1">Comment Auto Reply</h4>
                      <validation-observer ref="simpleRules2">
                        <b-form>
                          <b-row>
                            <b-col md="12">
                              <b-form-checkbox name="repeat" v-model="post.auto_reply" class="mb-75" switch inline>
                                Auto reply
                              </b-form-checkbox>
                            </b-col>
                            <template v-if="post.auto_reply">
                              <b-col cols="12" class="mb-2">
                                <div class="demo-inline-spacing">
                                  <b-form-radio v-model="post.auto_reply_type" name="some-radios" value="intent">
                                    AI Assistant Reply
                                  </b-form-radio>
                                  <b-form-radio v-model="post.auto_reply_type" name="some-radios" value="fixed">
                                    Fixed Reply Comment
                                  </b-form-radio>
                                </div>
                              </b-col>

                              <b-col cols="12" v-if="post.auto_reply_type == 'fixed'">
                                <b-form-group label="Auto Reply Comment" label-for="blog-content" class="mb-2">
                                  <validation-provider #default="{ errors }" name="Auto Reply Comment" rules="required">
                                    <b-form-textarea v-model="post.auto_reply_comment" id="textarea-default"
                                      :state="errors.length > 0 ? false : null" placeholder="Comment !" rows="3" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                            </template>
                          </b-row>
                        </b-form>
                      </validation-observer>
                    </div>
                  </b-col>
                  <b-col cols="12" v-if="post.id != null && post.status == 'scheduled'">
                    <div class="border rounded p-2">
                      <h4 class="mb-1">Schedule Date</h4>
                      <validation-observer ref="simpleRules2">
                        <b-form>
                          <b-row>
                            <b-col md="12">
                              <b-form-checkbox name="repeat" v-model="post.repeat" class="mb-75" switch inline>
                                Repeat Post
                              </b-form-checkbox>
                            </b-col>
                            <template v-if="!post.repeat">
                              <!-- date and time -->
                              <b-col md="6">
                                <b-form-group label="Date & TIme">
                                  <validation-provider #default="{ errors }" name="dateTime" rules="required">
                                    <VueflatPickr v-model="post.dateTime" class="form-control"
                                      :config="{ enableTime: true, time_24hr: true, dateFormat: 'Y-m-d H:i' }" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                            </template>
                            <template v-else>
                              <b-col md="12">
                                <b-form-group label="Period">
                                  <validation-provider #default="{ errors }" name="Period" rules="required">
                                    <v-select v-model="post.repeat_period"
                                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                                      @input="post.every = null" :options="['day', 'week', 'month']" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="12"
                                v-if="post.repeat_period != 'day' && post.repeat_period != null && post.repeat_period != ''">
                                <b-form-group label="Every">
                                  <validation-provider #default="{ errors }" name="Every" rules="required">
                                    <v-select v-model="post.every" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                      label="title"
                                      :reduce="post.repeat_period == 'week' ? item => item.id : item => item"
                                      :options="post.repeat_period == 'week' ? week_days : month_days" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="12">
                                <b-form-group label="Time">
                                  <validation-provider #default="{ errors }" name="Time" rules="required">
                                    <b-form-timepicker @context="onContext" v-model="post.time.time"
                                      id="datepicker-valid" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                            </template>
                          </b-row>
                        </b-form>
                      </validation-observer>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
              <!--/ form -->
            </validation-observer>
          </b-col>
          <b-col xl="4" lg="4" md="4" sm="12" cols="12">
            <b-tabs align="center" v-model="preview_indice">
              <b-tab>
                <template #title>
                  <b-img style="max-width: 25px;" :src="getChannelTypeImage('fb_page')" size="25" />
                </template>
              </b-tab>
              <b-tab>
                <template #title>
                  <b-img style="max-width: 25px;" :src="getChannelTypeImage('instagram')" size="25" />
                </template>
              </b-tab>
              <b-tab>
                <template #title>
                  <b-img style="max-width: 25px;" :src="getChannelTypeImage('linkedin_company')" size="25" />
                </template>
              </b-tab>
              <b-tab>
                <template #title>
                  <b-img style="max-width: 25px;" :src="getChannelTypeImage('twitter')" size="25" />
                </template>
              </b-tab>
              <b-tab>
                <template #title>
                  <b-img style="max-width: 25px;" :src="getChannelTypeImage('tiktok')" size="25" />
                </template>
              </b-tab>
              <b-tab>
                <template #title>
                  <b-img style="max-width: 30px;" :src="getChannelTypeImage('google_youtube')" size="25" />
                </template>
              </b-tab>
            </b-tabs>
            <b-row>
              <b-col cols="12">
                <Facebook v-if="preview_indice == 0" :post="post" />
                <Instagram v-if="preview_indice == 1" :post="post" />
                <Linkedin v-if="preview_indice == 2" :post="post" />
                <Twitter v-if="preview_indice == 3" :post="post" />
                <Tiktok v-if="preview_indice == 4" :post="post" />
              </b-col>
              <b-col cols="12">
                <div class="demo-spacing-0 mt-1">
                  <!-- <b-alert
                                        show
                                        variant="secondary"
                                    >
                                        <div class="alert-body">
                                            <feather-icon
                                                class="mr-25"
                                                icon="InfoIcon"
                                            />
                                            <span class="ml-25">{{tiktok.conf.max_video_post_duration_sec / 60}} minutes is the maximum video lenght for your Tiktok account</span>
                                        </div>
                                    </b-alert> -->
                  <b-alert show variant="secondary">
                    <div class="alert-body">
                      <feather-icon class="mr-25" icon="VideoIcon" />
                      <span class="ml-25">MP4 | WebM | MOV | 4 GB (max) | 10 minutes (max)</span>
                    </div>
                  </b-alert>
                  <b-alert show variant="secondary">
                    <div class="alert-body">
                      <feather-icon class="mr-25" icon="ImageIcon" />
                      <span class="ml-25">JPEG | WebP | 20 MB (max) | 1080p (max)</span>
                    </div>
                  </b-alert>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </template>

      <template #modal-footer="{ ok, cancel, hide }">

        <b-button variant="success" @click="processPost" :disabled="loading" class="mr-1">
          <template v-if="!loading">
            <span v-text="post.id == null ? 'Post Now' : 'Save'"></span>
          </template>
          <template v-else>
            <b-spinner small />
          </template>
        </b-button>
        <b-button v-if="post.id == null" variant="primary" @click="showPostDateModal = true" :disabled="loading">
          <template v-if="!loading">
            Schedule
          </template>
          <template v-else>
            <b-spinner small />
          </template>
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button :disabled="loading" variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <!-- modal medias -->
    <b-modal id="modal-multi-3" size="lg" title="Medias" ok-only ok-title="Add" cancel-title="Cancel"
      v-model="showMediaModal" no-close-on-backdrop @ok="addMediaToPost" @hidden="resetMediaModal"
      @show="resetMediaModal">
      <div class="mb-2 mx-2">
        <b-row v-if="post.type == 'video' || post.type == 'reels'">
          <b-col md="3" cols="3" class="profile-latest-img" v-for="video in videos" :key="video.id">
            <b-link @click="addMedia(video)">
              <b-overlay opacity="0.5" variant="dark" :show="mediaIsSelected(video.id)">
                <b-embed type="video" aspect="4by3">
                  <source :src="video.media">
                </b-embed>
                <template #overlay>
                  <div class="text-center">
                    <feather-icon size="50" class="text-success" icon="CheckIcon" />
                  </div>
                </template>
              </b-overlay>
            </b-link>
          </b-col>
          <b-col md="3" cols="3">
            <b-link @click="showModal = true">
              <b-avatar rounded="sm" variant="white" size="100" class="d-inline-block mr-1 mb-1 popular">
                <feather-icon icon="PlusIcon" class="text-primary" size="50" />
              </b-avatar>
            </b-link>
          </b-col>
          <b-col cols="12">
            <!-- pagination -->
            <div class="mt-3">
              <b-pagination v-model="currentPageVideo" align="center" :total-rows="videos_rows" :per-page="perPageVideo"
                @change="handlePaginationChangeVideo" first-number last-number prev-class="prev-item"
                next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col md="3" cols="3" class="profile-latest-img" v-for="image in images" :key="image.id">
            <b-link @click="addMedia(image)" v-on:dblclick="selectOneImg(image)">
              <b-overlay opacity="0.5" variant="dark" :show="mediaIsSelected(image.id)">
                <b-img fluid rounded :src="image.media" alt="image" />
                <template #overlay>
                  <div class="text-center">
                    <feather-icon size="50" class="text-success" icon="CheckIcon" />
                  </div>
                </template>
              </b-overlay>
            </b-link>
          </b-col>
          <b-col md="3" cols="3">
            <b-link @click="showModal = true">
              <b-avatar rounded="sm" variant="white" size="100" class="d-inline-block mr-1 mb-1 popular">
                <feather-icon icon="PlusIcon" class="text-primary" size="50" />
              </b-avatar>
            </b-link>
          </b-col>
          <b-col cols="12">
            <!-- pagination -->
            <div class="mt-3">
              <b-pagination v-model="currentPage" align="center" :total-rows="images_rows" :per-page="perPage"
                @change="handlePaginationChange" first-number last-number prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <!-- modal date -->
    <b-modal id="modal-center" centered v-model="showPostDateModal" no-close-on-backdrop title="Schedule Date" ok-only
      ok-title="Schedule" @ok="schedulePost" @hidden="post.is_scheduled = false">
      <validation-observer ref="simpleRules2">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-checkbox name="repeat" v-model="post.repeat" class="mb-75" switch inline>
                Repeat Post
              </b-form-checkbox>
            </b-col>
            <template v-if="!post.repeat">
              <!-- date and time -->
              <b-col md="6">
                <b-form-group label="Date & TIme">
                  <validation-provider #default="{ errors }" name="dateTime" rules="required">
                    <VueflatPickr v-model="post.dateTime" class="form-control"
                      :config="{ enableTime: true, time_24hr: true, dateFormat: 'Y-m-d H:i' }" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </template>
            <template v-else>
              <b-col md="12">
                <b-form-group label="Period">
                  <validation-provider #default="{ errors }" name="Period" rules="required">
                    <v-select v-model="post.repeat_period" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title" @input="post.every = null" :options="['day', 'week', 'month']" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12"
                v-if="post.repeat_period != 'day' && post.repeat_period != null && post.repeat_period != ''">
                <b-form-group label="Every">
                  <validation-provider #default="{ errors }" name="Every" rules="required">
                    <v-select v-model="post.every" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                      :reduce="post.repeat_period == 'week' ? item => item.id : item => item"
                      :options="post.repeat_period == 'week' ? week_days : month_days" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Time">
                  <validation-provider #default="{ errors }" name="Time" rules="required">
                    <b-form-timepicker @context="onContext" v-model="post.time.time" id="datepicker-valid" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </template>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- modal upload media -->
    <b-modal id="modal-1" title="Media Library" v-model="showModal" ok-only ok-title="Done" @ok="resetFiles"
      @show="resetFiles" @hidden="resetFiles">
      <b-row>
        <b-col cols="12">
          <b-form-file placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." multiple
            v-model="files" ref="files" @input="fileChange" />
        </b-col>

        <b-col cols="12">
          <div class="d-flex justify-content-start align-items-center mt-3" v-for="file in uploadFiles" :key="file.id">
            <b-avatar class="mr-50" size="40" variant="light-primary" rounded>
              <feather-icon size="19" :icon="file.type.split('/')[0] == 'video' ? 'VideoIcon' : 'ImageIcon'" />
            </b-avatar>
            <div class="user-page-info" style="width: 350px;">
              <h6 class="mb-0">{{ file.name }}</h6>
              <b-progress :max="max"
                :class="file.status == 'progress' ? 'progress-bar-warning' : file.status == 'done' ? 'progress-bar-success' : 'progress-bar-danger'"
                style="margin-top: 5px;">
                <b-progress-bar :value="file.progress" :label="`${((file.progress / max) * 100).toFixed(0)}%`"
                  :variant="file.status == 'progress' ? 'warning' : file.status == 'done' ? 'success' : 'failed'" />
              </b-progress>
            </div>
            <b-spinner v-if="file.status == 'progress'" type="grow" variant="warning" small class="mr-1 ml-auto" />
            <b-button v-if="file.status == 'done'" v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="success"
              class="btn-icon ml-auto" size="sm">
              <feather-icon icon="CheckIcon" />
            </b-button>
            <b-button v-if="file.status == 'failed'" v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="danger"
              class="btn-icon ml-auto" size="sm">
              <feather-icon icon="AlertCircleIcon" />
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import FullCalendar from '@fullcalendar/vue'
import axios from 'axios'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize, } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BModal, BButton, BMedia, BMediaBody, BMediaAside, BAvatar, BCardText, BRow, BCol, BForm, BLink, BImg, BFormGroup,
  BFormInput, BFormFile, BFormTextarea, BEmbed, BOverlay, BCard, BTabs, BTab, BFormTimepicker, VBModal, BFormCheckbox, BBadge,
  VBTooltip, BPagination, BSpinner, BProgress, BProgressBar, BAlert, BFormRadio
} from 'bootstrap-vue'
import VueflatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Instagram from '@/views/posts/previews/Instagram.vue'
import Facebook from '@/views/posts/previews/Facebook.vue'
import Linkedin from '@/views/posts/previews/Linkedin.vue'
import Twitter from '@/views/posts/previews/Twitter.vue'
import Tiktok from '@/views/posts/previews/Tiktok.vue'
export default {
  components: {
    CalendarSidebar, FullCalendar,
    Instagram, Facebook, Linkedin, Twitter, Tiktok,
    VueflatPickr, ToastificationContent, ValidationProvider, ValidationObserver, StatisticCardHorizontal,
    BModal, BButton, BMedia, BMediaBody, BMediaAside, BAvatar, BCardText, BRow, BCol, BForm, BLink, BImg, BFormGroup,
    BFormInput, BFormFile, BFormTextarea, vSelect, BEmbed, BOverlay, BCard, BTabs, BTab, BFormTimepicker, BFormCheckbox, BBadge,
    VBTooltip, BPagination, BSpinner, BProgress, BProgressBar, BAlert, BFormRadio
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    const calendarcolors = {
      Business: 'primary',
      posted: 'success',
      failed: 'danger',
      progressed: 'warning',
      scheduled: 'info',
    }
    return {
      isEventHandlerSidebarActive: true,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        },
        events: this.getData,

        /*
          Enable dragging and resizing event
          ? Docs: https://fullcalendar.io/docs/editable
        */
        editable: false,

        timeZone: this.$store.state.auth.user.current_brand_timezone != '' && this.$store.state.auth.user.current_brand_timezone != null ? this.$store.state.auth.user.current_brand_timezone : 'UTC',

        /*
          Enable resizing event from start
          ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
        */
        eventResizableFromStart: true,

        /*
          Automatically scroll the scroll-containers during event drag-and-drop and date selecting
          ? Docs: https://fullcalendar.io/docs/dragScroll
        */
        dragScroll: true,

        /*
          Max number of events within a given day
          ? Docs: https://fullcalendar.io/docs/dayMaxEvents
        */
        dayMaxEvents: 2,

        /*
          Determines if day names and week names are clickable
          ? Docs: https://fullcalendar.io/docs/navLinks
        */
        navLinks: true,

        eventClassNames({ event: calendarEvent }) {
          // eslint-disable-next-line no-underscore-dangle
          // const colorName = calendarcolors[Math.floor(Math.random()*calendarcolors.length)]
          const colorName = calendarcolors[calendarEvent._def.extendedProps.status]

          return [
            // Background Color
            `bg-light-${colorName}`,
          ]
        },
        eventClick: this.editPost,

        customButtons: {
          sidebarToggle: {
            // --- This dummy text actual icon rendering is handled using SCSS ----- //
            text: 'sidebar',
            click() {
              // eslint-disable-next-line no-use-before-define
              console.log('side click');
            },
          },
        },

        // Get direction from app state (store)
        direction: 'ltr',
        rerenderDelay: 350,
      },
      loading: false,
      preview_indice: 0,
      showPostDateModal: false,
      showRemoveVideo: false,
      showMediaModal: false,
      showPostModal: false,
      categoryOptions: [{ name: 'video', icon: 'VideoIcon' }, { name: 'normal', icon: 'ImageIcon' }, { name: 'reels', icon: 'VideoIcon' }],
      images: [],
      videos: [],
      channels: [],
      week_days: [{ id: "1", title: 'Monday' }, { id: "2", title: 'Tuesday' }, { id: "3", title: 'Wednesday' }, { id: "4", title: 'Thursday' }, { id: "5", title: 'Friday' }, { id: "6", title: 'Saturday' }, { id: "7", title: 'Sunday' }],
      month_days: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30"],
      post: {
        id: null,
        brand: null,
        title: '',
        caption: '',
        status: 'progressed',
        is_scheduled: false,
        type: 'normal',
        repeat: false,
        dateTime: null,
        time: {
          time: null,
          context: null
        },
        repeat_period: null,
        every: null,
        cron: null,
        auto_reply: false,
        auto_reply_type: 'intent',
        auto_reply_comment: '',
        channel_ids: [],
        medias: [],
        media_ids: []
      },
      selectedMedia: [],
      media: -1,
      posts: [],
      current_post: { title: '', channels: [] },
      post_insights: { title: '', channels: [] },
      uploadFiles: [],
      post_errors: [],
      files: null,
      max: 100,
      showModal: false,
      currentPage: 1,
      perPage: 12,
      images_rows: 0,
      currentPageVideo: 1,
      perPageVideo: 12,
      videos_rows: 0,
      required
    }
  },
  mounted() {
    this.getVideos()
    this.getImages()
    this.getChannels()
  },
  methods: {
    async getData(info, successCallback) {
      if (!info) return

      await axios.get(`/posts/calendar?brand_id=${this.$store.state.auth.user.current_brand}`)
        .then(response => {
          console.log(response);

          successCallback(response.data)
        })
        .catch(error => {
          console.log(error);
        })
    },
    editPost(arg) {
      this.updatePost(arg.event._def.extendedProps.object)
    },
    showAddPost() {
      console.log('addpost');
      this.resetPost();
      this.showPostModal = true;
    },
    processPost() {
      if (this.post.id != null) {
        if (this.post.is_scheduled) {
          this.schedulePost()
        } else {
          this.postNow()
        }
      } else {
        this.postNow()
      }
    },
    selectOneImg(img) {
      this.addMedia(img)
      this.addMediaToPost()
    },
    getChannelTypeImage(type) {
      if (type == "fb_page") return require('@/assets/images/channels/facebook.png')
      if (type == "instagram") return require('@/assets/images/channels/instagram.png')
      if (type == "twitter") return require('@/assets/images/channels/twitter-circle.png')
      if (type == "tiktok") return require('@/assets/images/channels/tiktok-circle.png')
      if (type == "linkedin_personal") return require('@/assets/images/channels/linkedin.png')
      if (type == "linkedin_company") return require('@/assets/images/channels/linkedin.png')
      if (type == "google_youtube") return require('@/assets/images/channels/youtube.png')
      if (type == "reddit") return require('@/assets/images/channels/reddit.png')
      if (type == "pinterest") return require('@/assets/images/channels/pinterest-circle.png')
      if (type == "google_business") return require('@/assets/images/channels/gmb.svg')
      if (type == "telegram") return require('@/assets/images/channels/telegram.png')
    },
    updatePost(post) {
      this.resetPost()
      console.log(post);
      this.post.id = post.id
      this.post.title = post.title
      this.post.caption = post.caption
      this.post.type = post.type
      this.post.status = post.status
      if (post.status == 'scheduled') {
        this.post.is_scheduled = true
      } else {
        this.post.is_scheduled = false
      }
      this.post.medias = post.medias
      this.post.auto_reply = post.auto_reply
      this.post.auto_reply_type = post.auto_reply_type
      this.post.auto_reply_comment = post.auto_reply_comment
      this.post.repeat = post.repeat
      this.post.repeat_period = post.repeat_period
      this.post.minute = post.minute
      this.post.hour = post.hour
      this.post.day_of_week = post.day_of_week
      this.post.day_of_month = post.day_of_month
      this.post.month_of_year = post.month_of_year
      this.post.time.time = `${post.hour}:${post.minute}:00`
      if (this.post.repeat_period == 'week') {
        this.post.every = post.day_of_week
      }
      else if (this.post.repeat_period == 'month') {
        this.post.every = post.day_of_month
      }
      else {
        this.post.dateTime = `${new Date().getFullYear()}-${this.post.month_of_year}-${this.post.day_of_month} ${this.post.hour}:${this.post.minute}`
      }
      post.channels.forEach(item => this.post.channel_ids.push(item.channel.id))
      this.showPostModal = true
    },
    onContext(ctx) {
      this.post.time.context = ctx
    },
    schedulePost(bvModalEvt) {
      this.post.is_scheduled = true
      if (bvModalEvt) {
        bvModalEvt.preventDefault()
      }
      this.$refs.simpleRules2.validate().then(success => {
        if (success) {
          if (this.post.repeat) {
            var cron;
            if (this.post.repeat_period == 'day') {
              cron = {
                repeat_period: 'day',
                minute: this.post.time.context.minutes,
                hour: this.post.time.context.hours,
                day_of_week: '*',
                day_of_month: '*',
                month_of_year: '*'
              }
            }
            if (this.post.repeat_period == 'week') {
              cron = {
                repeat_period: 'week',
                minute: this.post.time.context.minutes,
                hour: this.post.time.context.hours,
                day_of_week: this.post.every,
                day_of_month: '*',
                month_of_year: '*'
              }
            }
            if (this.post.repeat_period == 'month') {
              cron = {
                repeat_period: 'month',
                minute: this.post.time.context.minutes,
                hour: this.post.time.context.hours,
                day_of_week: '*',
                day_of_month: this.post.every,
                month_of_year: '*'
              }
            }
            console.log(cron);
          } else {
            const dateTime = new Date(this.post.dateTime);
            cron = {
              repeat_period: '',
              minute: dateTime.getMinutes(),
              hour: dateTime.getHours(),
              day_of_week: '*',
              day_of_month: dateTime.getDate(),
              month_of_year: dateTime.getMonth() + 1
            }
            console.log(cron);
          }
          this.post.cron = cron
          this.post.status = 'scheduled'
          this.is_scheduled = true
          this.addPost()
        }
      });
      console.log(this.post.repeat)
      console.log(this.post.dateTime)
      console.log(this.post.time)
      console.log(this.post.repeat_period)
      console.log(this.post.every);
    },
    postNow() {
      this.post.repeat = false
      this.is_scheduled = false
      this.post.status = 'progressed'
      this.addPost()
    },
    removeMedia(indice) {
      // delete this.post.medias[index]
      var newMedia = []
      this.post.medias.forEach((item, index) => {
        console.log(index + ' ' + item.id);
        if (index != indice) {
          newMedia.push(item)
        }
      })

      this.post.medias = newMedia
    },
    resetMediaModal() {
      this.selectedMedia = []
    },
    addMediaToPost() {
      this.selectedMedia.forEach(item => {
        this.post.medias.push(item)
      })
      this.showMediaModal = false
    },
    addMedia(media) {
      if (media.type == 'video') {
        this.selectedMedia = []
        this.selectedMedia.push(media)
      } else {
        if (this.mediaIsSelected(media.id)) {
          this.selectedMedia = this.selectedMedia.filter((item) => { return item.id != media.id })
        } else {
          this.selectedMedia.push(media)
        }
      }
    },
    mediaIsSelected(id) {
      var selected = false
      this.selectedMedia.forEach(item => {
        if (item.id == id) {
          selected = true
        }
      })
      return selected
    },
    resetPost() {
      this.post.id = null
      this.post.title = ''
      this.post.caption = ''
      this.post.type = 'normal'
      this.post.status = 'progressed'
      this.post.repeat = false
      this.post.is_scheduled = false
      this.post.auto_reply = false
      this.post.auto_reply_type = 'intent'
      this.post.auto_reply_comment = ''
      this.post.medias = []
      this.post.media_ids = []
      this.post.channel_ids = []
      this.showPostModal = true
    },
    async addPost() {
      this.loading = true
      console.log(this.post_errors);
      await this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let endpoint = '', action = '';
          this.post.media_ids = []
          for (let i = 0; i < this.post.medias.length; i++) {
            console.log(this.post.medias[i].id);
            this.post.media_ids.push(this.post.medias[i].id)
          }
          this.post.brand = this.$store.state.auth.user.current_brand
          if (this.post.id != null) {
            endpoint = `/posts/${this.post.id}/`
            action = 'put'
          } else {
            endpoint = '/posts/'
            action = 'post'
          }
          axios[action](endpoint, this.post)
            .then(response => {
              console.log(response);
              if (response.data.code == 400) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Upgrade Plan!',
                    icon: 'AlertCircleIcon',
                    text: 'Posts quota exceeded, please upgrade plan.',
                    variant: 'danger',
                  },
                })
                this.loading = false
              } else if (response.data.code == 401) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Upgrade Plan!',
                    icon: 'AlertCircleIcon',
                    text: 'Auto Reply not allowed for free plan, please upgrade plan.',
                    variant: 'danger',
                  },
                })
                this.loading = false
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Post processed!',
                    icon: 'CheckIcon',
                    text: 'Your post is in process.',
                    variant: 'success',
                  },
                })
                this.$refs.refCalendar.getApi().refetchEvents()
                this.resetPost()
                this.loading = false
                this.showPostModal = false
                this.showPostDateModal = false
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Warning !',
                  icon: 'AlertCircleIcon',
                  text: 'Something Went Wrong.',
                  variant: 'danger',
                },
              })
              this.loading = false
              console.log(error);
            })
        } else {
          this.loading = false
        }
      })
    },
    getChannels() {
      axios.get(`/channels?brand_id=${this.$store.state.auth.user.current_brand}`)
        .then(response => {
          this.channels = response.data
        })
        .catch(error => {
          console.log(error);
        })
    },
    deletePost(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.delete(`/posts/${id}/delete_post/`)
            .then(response => {
              console.log(response);
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                html: 'Your post has been deleted. Please note that delete post not available for TikTok and instagram, you should do it manual',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.$refs.refCalendar.getApi().refetchEvents()
            })
            .catch(error => {
              console.log(error);
            })
        }
      })
    },
    resetFiles() {
      this.uploadFiles = []
    },
    async fileChange() {
      console.log(this.files);
      await this.files.forEach(item => {
        const indice = this.uploadFiles.length
        this.uploadFiles.push({
          id: indice,
          name: item.name,
          type: item.type,
          status: 'progress',
          progress: 1,
        })

        let formData = new FormData()
        formData.append('brand', this.$store.state.auth.user.current_brand)
        formData.append('media', item)
        formData.append('type', item.type.split('/')[0])

        axios.post('/gallery/', formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: function (progressEvent) {
              this.uploadFiles[indice].progress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
              if (this.uploadFiles[indice].progress == 100) {
                this.uploadFiles[indice].status = 'done'
              }
            }.bind(this)
          })
          .then(response => {
            if (response.data.type == "image") {
              this.images.push(response.data)
            } else {
              this.videos.push(response.data)
            }
          })
          .catch(error => {
            this.uploadFiles[indice].status = 'failed'
            this.uploadFiles[indice].progress = 100
            console.log(error);
          })
      })
    },
    async handlePaginationChange(value) {
      this.currentPage = value
      await this.getImages()
    },
    async handlePaginationChangeVideo(value) {
      this.currentPageVideo = value
      await this.getVideos()
    },
    async getImages() {
      await axios.get(`/gallery?brand_id=${this.$store.state.auth.user.current_brand}&type=image&p=${this.currentPage}`)
        .then(response => {
          console.log(response);
          this.images_rows = response.data.count
          this.images = response.data.results
        })
        .catch(error => {
          console.log(error);
        })
    },
    async getVideos() {
      await axios.get(`/gallery?brand_id=${this.$store.state.auth.user.current_brand}&type=video&p=${this.currentPageVideo}`)
        .then(response => {
          console.log(response);
          this.videos_rows = response.data.count
          this.videos = response.data.results
        })
        .catch(error => {
          console.log(error);
        })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
@import '~@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>


<style>
.popular {
  border: 1px dashed var(--primary);
}

.fc-scrollgrid-sync-table {
  width: 100% !important;
}

.fc-col-header {
  width: 100% !important;
}

.fc-daygrid-body-unbalanced {
  width: 100% !important;
}
</style>